.root{
  
}

.title{
  font-family: Montserrat;
  font-size: 3em;
  font-weight: 600;
  line-height: 72.8px;
  text-align: center;
  color: #19191A;

  @media(max-width: 767px){
    font-size: 32px;
    font-weight: 600;
    line-height: 44.8px;
    text-align: left;
    padding: 0 15px;
  }

  & .colored{
    background: #C9FEC4;
    border-radius: 33px;
    display: inline-block;
    line-height: 45px;
    padding: 0 10px;
  }
}

.faqContainer{
  width: 80%;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 15px;

  & .faqWrapper{
    border: 1px solid gainsboro;
    border-radius: 42px;
    padding: 0 3em;

    & .content{
      font-family: Montserrat;
      font-size: 18px;
      font-weight: 500;
      line-height: 32px;
      text-align: left;
      margin-bottom: 10px;
    }
  }
  
  & .faqItem{
    display: flex;
    align-items: center;
    gap: 3em;
    cursor: pointer;

    & span{
      color: #19191A;
      display: inline-block;
      font-size: 2em;
    }

    & p{
      font-family: Montserrat;
      font-size: 24px;
      font-weight: 500;
      line-height: 38.4px;
      text-align: center;
      color: #19191A;
    }
  }

  @media(max-width: 767px){
    width: 90%;

    & .faqWrapper{
      padding: 0 12px;
      border-radius: 10px;
    }

    & .faqItem{
      gap: 15px;

      & p{
        font-size: 20px;
        font-weight: 500;
        line-height: 26px;
        text-align: left;
      }
    }
  }
}

.bookButtonWrapper{
  display: flex;
  justify-content: center;
  margin-top: 2em;
  
  & .ctaButton{
    background: #9238FF;
    text-decoration: none !important;
    padding: 12px 28px;
    border-radius: 28px;
    color: white;
    border: none;
    outline: none;
    width: fit-content;
    display: flex;
    gap: 20px;
    font-size: 18px;
  }

  @media(max-width: 767px){
    padding: 0 20px;
    
    & .ctaButton{
      width: 100%;
      justify-content: center;
    }
  }
}