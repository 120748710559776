.root{
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  position: relative;
  padding: 2em 0;
  background: white;
}

.descriptionContent{
  padding: 0 11em;
  margin-bottom: 20px;

  @media(max-width: 767px){
    padding: 0 15px;

    & h2{
      font-size: 32px;
      font-weight: 400;
      line-height: 39.01px;
    }

    & .description{
      font-weight: 600;
      margin: 0;
    }
  }
}

.title{
  font-family: Montserrat;
  font-size: 3rem;
  font-weight: 400;
  text-align: left;
  margin: 0;
  line-height: 40px;
}

.description{
  font-family: Montserrat;
  font-size: 3rem;
  font-weight: 600;
  text-align: left;
  margin: 10px 0;
  color: #9238FF;
}

.gridContainer{
  width: 98vw;
  overflow-x: auto;
  &::-webkit-scrollbar {
    display: none;
  }

  @media(max-width: 1500px){
    width: 100vw;
  }
}

.container{
  display: flex;
  gap: 20px;
  padding: 20px;
  cursor: pointer;
  animation: scroll-animation 10s linear infinite;
}

.firstContainer{
  margin-left: -10%;
}

@keyframes scroll-animation {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

.secondContainer{

}

/* Individual card styling */
.card {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  text-align: center;
  transition: transform 0.3s ease;
}

.card {
  background-color: white;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  min-width: 250px;
  text-align: center;
  font-family: Arial, sans-serif;
  position: relative;

  & .cardDescription{
    display: flex;
    align-items: center;
  }

  &::after{
    content: '';
    /* background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4)); */
    background: linear-gradient(180deg, rgba(240, 238, 234, 0.1) 66%, rgba(3, 3, 3, 1) 100%);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
  & img {
    width: 100%;
    height: 320px;
    object-fit: cover;
    border-bottom: 2px solid #6a0dad; /* Purple border */
  }

  @media(max-width: 767px){
    min-width: 225px;

    & img{
      height: 300px;
    }
  }
}

.card .details {
  padding: 15px;
  position: absolute;
  bottom: 2px;

  & h3 {
    margin: 0;
    font-size: 18px;
    color: white;
    line-height: 10px;
    text-align: left;
    position: relative;
    z-index: 1;
  }

  & p {
    margin: 0;
    font-size: 14px;
    color: white;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    position: relative;
    z-index: 1;
  }

  @media(max-width: 767px){
    & h3{
      font-size: 17.63px;
      font-weight: 600;
      line-height: 19.68px;
    }

    & p{
      font-size: 11.75px;
      font-weight: 500;
      line-height: 15.79px;
    }
  }
}

.card .details .arrow {
  margin-top: 10px;
  display: inline-block;
  color: white;
  font-size: 18px;
  cursor: pointer;
  position: relative;
  z-index: 1;
}

.card .details .arrow:hover {
  color: #4b0082; /* Darker purple on hover */
}

.bottomSectionWrapper{
  display: flex;
  flex-direction: column;
  align-items: center;

  & h2{
    font-family: Montserrat;
    font-size: 3em;
    font-weight: 600;
    line-height: 52px;
    text-align: center;
    width: 64%;
    margin: 0.5em auto;
    color: #19191A;
  }

  & .seeAllButton{
    background: #9238FF;
    text-decoration: none !important;
    padding: 12px 28px;
    border-radius: 28px;
    color: white;
    border: none;
    outline: none;
    width: fit-content;
    display: flex;
    gap: 20px;
    font-size: 18px;
  }

  & .greenBackgroundText{
    background: #C9FEC4;
    border-radius: 35px;
    padding: 0px 8px;
    line-height: 40px;
    display: inline-block;
  }

  @media(max-width: 767px){
    & h2{
      margin: 0;
      font-size: 31px;
      font-weight: 600;
      line-height: 39.01px;
      width: unset;
      padding: 0 14px;
      margin-bottom: 1em;
    }
  }
}