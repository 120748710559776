.root{
  background: white;
  padding-bottom: 1em;
}

.title{
  font-family: Montserrat;
  font-size: 3em;
  font-weight: 600;
  line-height: 72.8px;
  text-align: center;
  color: #19191A;
  margin-bottom: 1em;

  @media(max-width: 767px){
    font-size: 32px;
    font-weight: 600;
    line-height: 44.8px;
    margin-top: 0;
    margin-bottom: 1.5em;
  }
}

.tagsContainer{
  display: flex;
  /* gap: 5px; */
  justify-content: center;

  & span{
    font-family: Montserrat;
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 35.2px;
    text-align: center;
    background: blueviolet;
    display: inline-block;
    padding: 4px 20px;
    border-radius: 30px;

    &:nth-child(1){
      background: #F5F4EE;
      transform: rotate(-8deg);
    }
    &:nth-child(2){
      background: #9238FF;
      color: white;
    }
    &:nth-child(3){
      background: #DBEFE8;
      transform: rotate(12deg);
    }
    &:nth-child(4){
      background: #F5F4EE;
    }
    &:nth-child(5){
      background: #FFFAC0;
      transform: rotate(-8deg);
    }
    &:nth-child(6){
      background: #FEF2FF;
      transform: rotate(21deg) translate(-17px, -12px);
    }
    &:nth-child(7){
      background: #C9FEC4;
      transform: rotate(15deg) translate(-32px, -8px);
    }
    &:nth-child(8){
      background: #FFE3D3;
      transform: translateX(-32px);
    }
    &:nth-child(9){
      background: #FEF2FF;
      transform: translateX(-32px);
    }
    &:nth-child(10){
      background: #FFEA9F;
      transform: rotate(30deg) translate(-100px, 25px);
    }
  }

  @media(min-width: 768px){
    width: 96vw;
    padding-left: 10em;
  }

  @media(max-width: 767px){
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 5em;
    padding-left: 32px;

    & span{  
      width: fit-content;
      
      &:nth-child(1){
        transform: rotate(-8deg) translate(44px, -10px);
      }
      &:nth-child(2){
        transform: translate(75px, -11px);
      }
      &:nth-child(3){
        transform: rotate(9deg) translate(-40px, -10px);
        grid-area: 1 / 2;
      }
      &:nth-child(4){
        transform: translate(20px, -6px);
      }
      &:nth-child(5){
        transform: rotate(-8deg) translate(-13px, 18px);
      }
      &:nth-child(6){
        transform: rotate(23deg) translate(35px, -11px);
        grid-area: 2 / 2;
      }
      &:nth-child(7){
        transform: rotate(17deg) translate(4px, 50px);
        grid-area: 4 / 2;
      }
      &:nth-child(8){
        transform: translate(21px, 0px);
      }
      &:nth-child(9){
        transform: translate(22px, 4px);
      }
      &:nth-child(10){
        transform: rotate(-4deg) translate(-62px, 53px);
      }
    }
  }
}