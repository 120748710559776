.root{
  padding: 3em 0 2em;

  @media(max-width: 767px){
    padding: 2em 0 2em;
  }
}

.title{
  font-family: Montserrat;
  font-size: 3em;
  font-weight: 600;
  line-height: 70px;
  text-align: center;
  width: 50%;
  margin: auto;
  color: #000000;

  & span{
    background: #C9FEC4;
    border-radius: 33px;
    display: inline-block;
    line-height: 45px;
    padding: 0 10px;
  }

  @media(max-width: 767px){
    width: 98%;
    font-size: 32px;
    font-weight: 600;
    line-height: 41.6px;
  }
}

.description{
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 500;
  line-height: 32px;
  text-align: center;
  width: 60%;
  margin: auto;
  color: #000000;

  @media(max-width: 767px){
    font-size: 18px;
    font-weight: 400;
    line-height: 23.4px;
    width: 81%;
    margin-top: 15px;
  }
}

.searchIcon{

}

.container{
  display: flex;
  gap: 1.5em;
  justify-content: center;
  margin: 2em 0 2.5em;

  @media(max-width: 767px){
    display: block;
    padding: 0 15px;;
  }

  & .card{
    border: 1px solid gainsboro;
    border-radius: 14px;
    width: 26%;
    padding: 24px 30px;
    display: flex;
    flex-direction: column;

    & img{
      width: 60px;
      height: 60px;
      object-fit: contain;
    }

    & h3{
      font-family: Montserrat;
      font-size: 24px;
      font-weight: 600;
      line-height: 33.6px;
      text-align: left;
      color: #000000;
      margin: 8px 0;
    }

    & p{
      font-family: Montserrat;
      font-size: 18px;
      font-weight: 400;
      line-height: 25.2px;
      text-align: left;
      color: #000000;
      margin: 8px 0;
      padding: 0;
    }

    @media(max-width: 767px){
      width: unset;
      margin-bottom: 1em;
      padding: 15px 22px;
    
      & img{
        width: 45px;
        height: 45px;
      }
    
      & h3{
        font-size: 20px;
        font-weight: 600;
        line-height: 28px;
      }
    
      & p{
        font-size: 18px;
        font-weight: 400;
        line-height: 25.2px;
      }
    }
  }
}

.bookButtonWrapper{
  display: flex;
  justify-content: center;
  
  & .ctaButton{
    background: #9238FF;
    text-decoration: none !important;
    padding: 12px 28px;
    border-radius: 28px;
    color: white;
    border: none;
    outline: none;
    width: fit-content;
    display: flex;
    gap: 20px;
    font-size: 18px;
    cursor: pointer;
  }

  @media(max-width: 767px){
    padding: 0 20px;
    
    & .ctaButton{
      width: 100%;
      justify-content: center;
    }
  }
}