.slider {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
}

.slider-container {
  width: 98vw; /* Adjusted width to allow more spacing */
  overflow: hidden;
  position: relative;
  margin-bottom: 20px; /* Space between slider and arrows */
}

.slider-track {
  display: flex;
  gap: 30px;
  transition: transform 0.5s ease;
  transform: translateX(-406.5px);

  @media(max-width: 1400px){
    transform: translateX(-335px);
  }

  @media(max-width: 767px){
    transform: translateX(15px);
  }
}

/* Style for the arrow buttons */
.slider-arrows {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px; /* Space between the two arrows */
}

.slider-arrow {
  background-color: #6aa584;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  color: white;
  font-size: 24px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slider-arrow:hover {
  background-color: #49976b;
}

.arrowWrapper{
  display: flex;
  justify-content: center;
  gap: 1.5em;

  & button{
    border: 1px solid #19191A;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    cursor: pointer;
  }
}

/* Style for the individual slider items */
.slider-item {
  align-items: center;
  background: #faf9f6;
  border-radius: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%; 
  margin: 0; 
  font-size: 24px;
  flex-shrink: 0;
  padding: 2em 4em;
  transition: transform 0.5s ease, opacity 0.5s ease;

  @media(max-width: 767px){
    min-width: 92%;
    padding: 15px;
    justify-content: flex-start;
  }
}

.slider-item img{
  width: 95px;
  height: 95px;
  border-radius: 50%;
}

.slider-item h3{
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 600;
  line-height: 32px;
  text-align: center;
  color: #9238FF;

  @media(max-width: 767px){
    font-size: 20px;
    font-weight: 600;
    line-height: 24.38px;
  }
}

.slider-item h4{
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 700;
  line-height: 32px;
  text-align: center;
  color: #19191A;

  @media(max-width: 767px){
    font-size: 20px;
    font-weight: 700;
    line-height: 32px;
  }
}

.slider-item p{
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  text-align: center;
  color: #19191A;
  
  @media(max-width: 767px){
    font-size: 16px;
    font-weight: 500;
    line-height: 24.38px;
  }
}