.root{
  background: #F5F4EE;
  padding: 2em 4em;
  margin: 2em 0;

  @media(max-width: 767px){
    padding: 15px;
    margin: 0;
  }
}

.descriptionWrapper{
  display: grid;
  grid-template-columns: 30% 50%;
  justify-content: space-around;

  @media(max-width: 767px){
    display: flex;
    flex-direction: column;
  }
}

.title{
  font-family: Montserrat;
  font-size: 3em;
  font-weight: 600;
  line-height: 75px;
  text-align: left;
  color: #000000;

  @media(max-width: 767px){
    font-size: 32px;
    font-weight: 600;
    line-height: 41.6px;
    padding-right: 10px;
  }
}

.description{
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 500;
  line-height: 32px;
  text-align: left;
  color: #000000;

  @media(max-width: 767px){
    font-size: 18px;
    font-weight: 400;
    line-height: 23.4px;
  }
}

.subTitle{
  font-family: Montserrat;
  font-size: 32px;
  font-weight: 600;
  line-height: 32px;
  text-align: center;
  color: black;
  margin: 2em 0 1em;

  & span{
    color: #9238FF;
  }

  @media(max-width: 767px){
    font-size: 24px;
    font-weight: 600;
    line-height: 31.2px;
    margin-top: 1em;
  }
}

.container{
  display: flex;
  justify-content: space-around;
  min-width: 80%;
  margin: 2em auto;

  & .borderBackground{
    padding: 0;
    position: relative;

    & h3{
      color: #9238FF;
      z-index: 1;
    }

    &::after{
      content: "";
      position: absolute;
      width: 230px;
      height: 230px;
      border-radius: 50%;
      background: #F5F4EE;
      top: 9px;
      left: 9px;
    }
  }

  & .circle{
    width: 250px;
    height: 250px;
    border: 1px solid white;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 2.5em;
    background-color: #9238FF;

    &:nth-child(2){
      background-color: #9238FF;
      color: white;
      border: none;
      transform: translateY(-50px);
      
      & h3{
        /* color: white; */
      }
    }

    &:nth-child(3){
      transform: translateY(50px);
    }

    & h3{
      margin: 0;
      font-family: Montserrat;
      font-size: 46px;
      font-weight: 600;
      line-height: 40px;
      text-align: left;
      padding: 0;
      transform: translateY(10px);
    }

    & p{
      margin: 0;
      font-family: Montserrat;
      font-size: 16px;
      font-weight: 500;
      line-height: 28px;
      text-align: left;
      transform: translateY(10px);
      color: black;
      z-index: 1;
    }
  }

  @media(max-width: 767px){
    flex-direction: column;
    margin: 0 auto;
    width: 75%;

    & .circle{
      width: 160px;
      height: 160px;

      &:nth-child(2){
        transform: unset;
        align-self: flex-end;
      }
      &:nth-child(3){
        transform: unset;
      }
      &:nth-child(4){
        align-self: flex-end;
      }

      & h3{
        font-size: 31.6px;
        font-weight: 600;
        line-height: 44.24px;
        transform: unset;
      }
  
      & p{
        font-size: 10.15px;
        font-weight: 500;
        line-height: 17.02px;
        transform: unset;
      }
    }

    & .borderBackground{
      &::after{
        width: 150px;
        height: 150px;
        top: 5px;
        left: 4px;
      }
    }
  }
}

.bookButtonWrapper{
  display: flex;
  justify-content: center;
  
  & .ctaButton{
    background: #9238FF;
    text-decoration: none !important;
    padding: 12px 28px;
    border-radius: 28px;
    color: white;
    border: none;
    outline: none;
    width: fit-content;
    display: flex;
    gap: 20px;
    font-size: 18px;
    cursor: pointer;
  }

  @media(max-width: 767px){
    & .ctaButton{
      width: 95%;
      justify-content: center;
    }
  }
}