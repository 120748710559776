.root{
  background: #FEF2FF;
  padding: 2em 10em;
  width: 95vw;
  margin: auto;
  border-radius: 24px;
  margin-top: 2em;

  @media(max-width: 767px){
    padding: 10px 15px 2em;
    margin-top: 1em;
  }
}

.title{
  font-family: Montserrat;
  font-size: 3em;
  font-weight: 600;
  line-height: 52.6px;
  text-align: center;
  color: black;

  @media(max-width: 767px){
    font-size: 32px;
    font-weight: 600;
    line-height: 39.01px;
  }

  & .colored{
    background-color: bisque;
    border-radius: 35px;
    padding: 0px 8px;
    line-height: 40px;
    display: inline-block;
  }
}

.description{
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
  text-align: center;
  margin-bottom: 1.8em;
  color: black;

  @media(max-width: 767px){
    font-size: 20px;
    font-weight: 400;
    line-height: 26px;
  }
}

.container{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 2em 3em;

  & .card{
    & img{
      width: 70px;
      height: 70px;
    }

    & h3{
      font-family: Montserrat;
      font-size: 24px;
      font-weight: 600;
      line-height: 33.6px;
      text-align: left;
      color: black;
    }

    & p{
      font-family: Montserrat;
      font-size: 18px;
      font-weight: 500;
      line-height: 28.8px;
      text-align: left;    
      color: black;  
    }
  }

  @media(max-width: 767px){
    display: block;

    & .card{
      margin-bottom: 0.5em;

      & img{
        width: 55px;
        height: 55px;
      }
  
      & h3{
        font-size: 20px;
        font-weight: 600;
        line-height: 28px;
      }
  
      & p{
        font-size: 18px;
        font-weight: 400;
        line-height: 23.4px;
      }
    }
  }
}

.bookButtonWrapper{
  display: flex;
  justify-content: center;
  
  & .ctaButton{
    background: #9238FF;
    text-decoration: none !important;
    padding: 12px 28px;
    border-radius: 28px;
    color: white;
    border: none;
    outline: none;
    width: fit-content;
    display: flex;
    gap: 20px;
    font-size: 18px;
  }

  @media(max-width: 767px){
    margin-top: 2em;

    & .ctaButton{
      width: 96%;
      font-size: 18px;
      font-weight: 600;
      line-height: 28.8px;
      display: flex;
      justify-content: center;
    }
  }
}
