.root{
  padding-top: 2em;

  @media(max-width: 767px){
    padding-top: 1em;
  }
}

.title{
  font-family: Montserrat;
  font-size: 28px;
  font-weight: 600;
  line-height: 39.2px;
  text-align: center;
  color: black;
}

.iconWrapper{
  display: flex;
  justify-content: center;
  gap: 6em;
  border-top: 1px solid #D1D1D1;
  border-bottom: 1px solid #D1D1D1;
  padding: 1.5em 0;

  & img{
    width: 5%;
    object-fit: contain;
  }

  @media(max-width: 767px){
    /* width: 98vw; */
    justify-content: flex-start;
    overflow-x: auto;
    gap: 3em;
    padding: 1em 10px;

    &::-webkit-scrollbar {
      display: none;
    }

    & img{
      width: 15%;
      object-fit: contain;
    }
  }
}