.root{
  width: 80%;
  background: #FEF2FF;
  border-radius: 26px;
  padding: 2em 4em;
  margin: 2em auto 3em;

  @media(max-width: 767px){
    width: 90%;
    padding: 0 15px 2em;
  }
}

.title{
  font-family: Montserrat;
  font-size: 3em;
  font-weight: 600;
  line-height: 72px;
  text-align: center;
  color: #19191A;

  @media(max-width: 767px){
    font-size: 32px;
    font-weight: 600;
    line-height: 41.6px;
  }
}

.description{
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 500;
  line-height: 32px;
  text-align: center;
  color: black;
  margin-bottom: 1.8em;

  @media(max-width: 767px){
    font-size: 18px;
    font-weight: 400;
    line-height: 23.4px;
  }
}

.bookButtonWrapper{
  display: flex;
  justify-content: center;
  
  & .ctaButton{
    background: #9238FF;
    text-decoration: none !important;
    padding: 12px 28px;
    border-radius: 28px;
    color: white;
    border: none;
    outline: none;
    width: fit-content;
    display: flex;
    gap: 20px;
    font-size: 18px;
    cursor: pointer;
  }

  @media(max-width: 767px){
    padding: 0 20px;
    
    & .ctaButton{
      width: 100%;
      justify-content: center;
    }
  }
}